import styled, { css } from "styled-components"

// The ID prop referenced below relates to the homepage carousel

export const CarouselContentBlockWrapper = styled.div`
  padding: ${props =>
    props.id === "c6cd0b2b-57e8-5ed6-9bbb-0f604d5460ba"
      ? "135px 60px 96px"
      : "96px 60px"};
  font-family: "Roboto", sans-serif;
  position: relative;
  overflow: hidden;
  background-image: "#F5F5F5";
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media (max-width: 992px) {
    padding: ${props =>
      props.id === "c6cd0b2b-57e8-5ed6-9bbb-0f604d5460ba"
        ? "235px 60px 96px"
        : "56px 40px"};
  }
  @media (max-width: 767px) {
    padding: ${props =>
      props.id === "c6cd0b2b-57e8-5ed6-9bbb-0f604d5460ba"
        ? "145px 48px 60px"
        : "40px 40px"};
  }
  @media (max-width: 576px) {
    padding: 40px 20px;
  }
  ${({ noTopPadding, noBottomPadding }) => css`
    padding-top: ${noTopPadding === true && 0};
    padding-bottom: ${noBottomPadding === true && 0};
    @media (max-width: 992px) {
      padding-top: ${noTopPadding === true && 0};
      padding-bottom: ${noBottomPadding === true && 0};
    }
    @media (max-width: 768px) {
      padding-top: ${noTopPadding === true && 0};
      padding-bottom: ${noBottomPadding === true && 0};
    }
  `}
  .slick-slider {
    .slick-track {
      display: flex;
      align-items: center;
    }
    .slick-dots {
      bottom: -64px;
      @media (max-width: 576px) {
        bottom: -25px;
      }
      li {
        width: 14px;
        height: 14px;
        margin: 0 10px;
        button {
          width: 14px;
          height: 14px;
          padding: 0;
          border: 1px solid #fff;
          border-radius: 50%;
          &:before {
            font-size: 14px;
            line-height: 16px;
            width: 14px;
            height: 14px;
            color: #e4e7ec;
            opacity: 1;
          }
        }
        &.slick-active {
          button:before {
            color: #0022b7;
          }
        }
      }
    }
  }
  .home-improve-section--featured-item-quote-container {
    background: #f2f4f7;
    border-radius: 8px;
    padding: 24px;
    margin: 24px 0 0;
    p {
      margin: 0;
    }
    .home-improve-section--featured-item-quote-speaker {
      margin: 12px 0 0;
      display: flex;
      align-items: center;
      img {
        width: 24px;
        height: 24px;
      }

      span {
        margin-left: 12px;
        font-size: 12px;
        line-height: 16px;
        color: #4d4d4d;
      }
    }
  }
`

export const SectionContainer = styled.div`
  position: relative;
  z-index: 20;
`

export const CopyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const SectionHeading = styled.h2`
  font-weight: 900;
  font-size: 50px;
  line-height: 60px;
  text-align: center;
  color: #1b1b1b;
  max-width: 770px;
  @media (max-width: 768px) {
    font-size: 44px;
    line-height: 48px;
  }
  @media (max-width: 576px) {
    font-size: 38px;
    line-height: 44px;
  }
`

export const SectionDescription = styled.p`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #475467;
  margin: 16px 0 0;
  max-width: 770px;
`

export const FeaturedContainer = styled.div`
  padding: 56px 57px 64px;
  @media (max-width: 992px) {
    padding: 60px 0 70px;
  }
  @media (max-width: 576px) {
    padding: 60px 0 30px;
  }
`

export const FeaturedWrapper = styled.div`
  @media (max-width: 768px) {
    margin: 0 10px;
  }
`

export const FeaturedItem = styled.div`
  padding: 0 28px;
`

export const FeaturedItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1270px;
  margin: 0 auto;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const ItemCopyContainer = styled.div`
  flex: 4;
  max-width: 470px;
  @media (max-width: 768px) {
    max-width: 100%;
  }
  ul {
    display: block;
    li {
      font-size: 16px;
      line-height: 24px;
      color: #4d4d4d;
      position: relative;
      padding: 8px 0 8px 0;
      &:before {
        content: " ";
        width: 24px;
        height: 24px;
        position: absolute;
        background-image: url("/icons/check-blue.svg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        left: 0;
        top: 8px;
      }
    }
  }
  button {
    margin-top: 8px !important;
    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 24px;
    }
  }
`

export const ItemHeading = styled.h4`
  font-weight: bold;
  font-size: 38px;
  line-height: 42px;
  color: #1a2834;
  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 38px;
  }
  @media (max-width: 576px) {
    font-size: 32px;
    line-height: 38px;
  }
`

export const ItemDescription = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: #4d4d4d;
  margin: 16px 0 0;
`

export const ItemImageContainer = styled.div`
  flex: 6;
  .gatsby-image-wrapper {
    height: 100%;
    max-height: 500px;
    img {
      margin: 0 auto;
      object-fit: contain !important;
    }
  }

  @media (max-width: 768px) {
    margin: 24px 0 0 !important;
  }
`
