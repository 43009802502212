import React, { createRef } from "react"
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import OptimizedImage from "../../utils/optimizedImage";
import { TopWrapper, Heading, CardSubheading } from "./styles/CarouselFullWidth.styled";

import { ImageCarouselWrapper } from './styles/styles';

export const CarouselFullWidth = ({ component }) => {
  const {
    carouselItems,
    heading,
    autoplay,
    noTopPadding,
    noBottomPadding,
  } = component;
  const sliderRef = createRef();

  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    autoplay: autoplay,
    draggable: true,
    swipeToSlide: true,
    variableWidth: true,
    speed: 700,
    autoplaySpeed: 2000,
    appendDots: dots => (
      <ul>
        <div className="slick-arrow-btn slick-prev-btn" onClick={() => sliderRef?.current?.slickPrev()} role="button" tabIndex={0} />
        {dots}
        <div className="slick-arrow-btn slick-next-btn" onClick={() => sliderRef?.current?.slickNext()} role="button" tabIndex={0} />
      </ul>
    ),
  };

  return (
    <ImageCarouselWrapper className="image-carousel--full-width-wrapper" noTopPadding={noTopPadding} noBottomPadding={noBottomPadding}>
      <div className="image-carousel--container">
        <TopWrapper>
          <Heading>
            <p>{heading}</p>
          </Heading>
        </TopWrapper>
        <div className="image-carousel--main-container">
          <Slider ref={sliderRef} {...sliderSettings}>
            {carouselItems?.map((item, index) => (
              <div className="image-carousel--item" key={index}>
                {item?.heading && (
                  <CardSubheading className="card-text">{item?.heading}</CardSubheading>
                )}
                <div className="image-carousel--item-wrapper">
                  <OptimizedImage className="image-carousel--item-image" src={item?.featuredImage?.file?.url} image={item?.featuredImage?.gatsbyImageData} alt="" />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </ImageCarouselWrapper>
  )
}
