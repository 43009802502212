import React from "react"
import { switchbackResolveRichText } from "@utils/resolve-rich-text/switchbackResolveRichText"
import OptimizedImage from "../../../utils/optimizedImage"
import { Button } from "../../../atoms/Button"
import { 
  FeaturedItem, 
  FeaturedItemWrapper,
  ItemCopyContainer,
  ItemHeading,
  ItemDescription,
  ItemImageContainer
} from "../styles/CarouselContentBlock.styled"

const ContentBlockItem = ({ item }) => {
  const {
    heading,
    subheading,
    callsToAction,
    featuredImage,
  } = item

  return (
    <div>
      <FeaturedItem>
        <FeaturedItemWrapper>
          <ItemCopyContainer>
            <ItemHeading>{heading}</ItemHeading>
            {subheading && (
              <ItemDescription>
                {switchbackResolveRichText(subheading)}
              </ItemDescription>
            )}
            {callsToAction && callsToAction && (
              <Button style={{ marginTop: '30px' }} ctaVariant={callsToAction[0]?.variant || 'primary' } ctaLink={callsToAction[0]?.link}>
                {callsToAction[0]?.label}
              </Button>
            )}
          </ItemCopyContainer>
          <ItemImageContainer>
            <OptimizedImage
              image={featuredImage?.gatsbyImageData}
              src={
                featuredImage?.file?.url ||
                "/images/home-improve-feature-3.png"
              }
              alt=""
            />
          </ItemImageContainer>
        </FeaturedItemWrapper>
      </FeaturedItem>
    </div>
  )
}

export default ContentBlockItem